import React from 'react';
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Section1 from './components/Section1'
import Footer from './components/Footer'
import Contact from './components/Contact'
import Instagram from './components/Instagram'



function App() {
  return (
    <div>
    <Navbar />
    <Hero />
    <Section1 />
    <Instagram />
    <Contact />
    <Footer />
    </div>
  );
}

export default App;
