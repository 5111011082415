import React from "react";
import { ReactTyped } from "react-typed";
import styled from "styled-components";
import { useState } from "react";
import bgpic from '../assets/bgpic.png'

const Button = styled.button`
  background-color: #F2CC0C;
  color: black;
  padding: 10px 30px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 4px 4px white;
  transition: ease background-color 250ms;
  &:hover {
    background-color: #F2E30C;
  }
`;
const Hero = () => {
  return (
    
    <div className="text-white relative">
  {/* Background Image */}
  <img
    src={bgpic}
    alt="Description of your image"
    className="absolute inset-0 object-cover w-full h-full opacity-30"
    style={{ zIndex: -1 }}
  />

  {/* Content */}
  <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center relative z-10">
    <ReactTyped
      className="pl-2 md:pl-4 md:text-2xl sm:text-l text-l font-bold text-white pt-8"
      strings={[
        "15 years in business, serving the Kitchener - Waterloo and surrounding areas",
      ]}
      typeSpeed={20}
      backspeed={140}
      onComplete={(self) => self.cursor.remove()}
    />

    <h1 className="md:text-8xl sm:text-7xl text-5xl font-bold md:py-6 pt-3 text-white">
      Get Instant Quote Now
    </h1>
    <div className="flex justify-center items-center ">
      <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4"></p>
    </div>
    <p className="md:text-2xl text-xl font-bold text-gray-500"></p>
    <div style={{ display: "flex", justifyContent: "center" }}>
      <a href="#Contact" style={{ marginRight: "30px" }}>
        <Button>Click for Quote</Button>
      </a>
      <a href="#insta">
        <Button>Check Our Work</Button>
      </a>
    </div>
  </div>
</div>
  );
};

export default Hero;
