import React from 'react'
import { InstagramEmbed } from 'react-social-media-embed';
import styles from './styles.module.css'; // Import the CSS module


const Instagram = () => {
  return (
    <section id="insta">

    <div className="">
      <h1 className="pt-10 py-4 text-5xl font-bold text-center text-[#F2CC0C]">
        Our Work
      </h1>    
<div style={{ display: 'flex', marginTop: '100px', justifyContent: 'center' }}>
      {/* Instagram reel 1 */}
      <InstagramEmbed url="https://www.instagram.com/reel/C50yH6fLLDH/" width={340} style={{marginRight: '50px'}}/>

      {/* Show the second and third reels only on larger screens */}
      <div className={styles['desktop-only']} style={{ marginRight: '50px' }}>
        <InstagramEmbed url="https://www.instagram.com/reel/C4-8CP0KWSI/" width={340} />
      </div>
      <div className={styles['desktop-only']}>
        <InstagramEmbed url="https://www.instagram.com/reel/C4lIqn8KPXb/" width={340} />
      </div>
  </div>
  </div>
  </section>




  )
}

export default Instagram

