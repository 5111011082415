import React from 'react';
import logo from '../assets/logo.png';


const Section1 = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        <img className='rounded-md w-[500px] mx-auto md:mx-auto my-4' src={logo} alt='kwpc cellphone repair' />
        <div className='flex flex-col justify-center'>
          <p className='text-[#060B73] font-bold text-3xl'>Kitchener Location</p>
          <h1 className='text-4xl font-bold py-2'>309 Lancaster Street West, Kitchener Ontario.
</h1>
<h2 className='text-3xl text-[#060B73] font-bold py-2'>Hours of Operation
</h2>
<h2 className='text-xl font-bold py-2'>Monday to Friday 10 AM to 6 Pm

</h2>
<h2 className='text-xl font-bold py-2'>Saturday 10 AM to 5 PM
</h2>
<h2 className='text-xl font-bold py-2'>Sunday 10 AM to 5 PM
</h2>

          <p>

          </p>
        </div>
      </div>
    </div>
  );
};



export default Section1;