import React from 'react';
import {
  FaFacebookSquare,
  FaInstagramSquare
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 flex justify-center text-[#0C0C0D'>
      {/* First Section */}
      <div className="flex-1">
        <h1 className='w-full text-3xl font-bold text-[#F2CC0C]'>KW-Cell</h1>
        <p className='py-4 text-white text-xl'>
          309 Lancaster St. West Unit 1. Kitchener Ontario
        </p>
        <p className='py-4 font-bold text-[#F2CC0C] text-2xl'>
          (519) 594-0059
        </p>
        <div className='flex md:w-[75%] my-3'>
          <a
            className=""
            href="https://www.facebook.com/cellexperts"
          >
            <FaFacebookSquare className="cursor-pointer" size={30} color='white' />
          </a>
          <a
            className=""
            href="https://www.instagram.com/kwpccellphonerepair/"
          >
            <FaInstagramSquare className="cursor-pointer" size={30} color='white' />
          </a>
        </div>
        </div>

{/* Second Section */}

        
    </div>
  );
};

export default Footer;
