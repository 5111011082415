import React from "react";
import { Fade } from "react-awesome-reveal";


const Contact = () => {
  return (
    <Fade>
    <div id="contact" className="max-w-[1040px] m-auto md:pl-20 p-4 py-16">
    <section id="Contact">
      <h1 className="py-4 text-5xl font-bold text-center text-[#F2CC0C]">
        Get Quote
      </h1>

      <form action="https://getform.io/f/paqgyyla" method="POST" encType="multipart/form-data">
        <div className='grid md:grid-cols-2 gap-4 w-full py-2'>
          <div className='flex flex-col py-2'>
            <label className='uppercase text-white text-xl py-2'>Name</label>
            <input className='border-2 rounded-lg p-3 flex border-gray-300' type='text' name='name' required />
          </div>
        <div className='flex flex-col py-2'>
          <label className='uppercase text-white text-xl py-2'>Phone</label>
          <input className='border-2 rounded-lg p-3 flex border-gray-300' type='text' name='phone' required/>
        </div>
        </div>
        <div className='flex flex-col py-2'>
          <label className='uppercase text-white text-xl py-2'>Email</label>
          <input className='border-2 rounded-lg p-3 flex border-gray-300' type='email' name='email' required />
        </div>
        <div className='flex flex-col py-2'>
          <label className='uppercase text-white text-xl py-2'>Device Name & Model Number
</label>
          <input className='border-2 rounded-lg p-3 flex border-gray-300' type='text' name='subject' required />
        </div>
        <div className='flex flex-col py-2'>
          <label className='uppercase text-xl text-white py-2'>What is wrong with your device?
</label>
          <textarea className='border-2 rounded-lg p-3 border-gray-300' rows='10' name='message' required></textarea>
        </div>
        <button className='bg-[#F2CC0C] text-black text-3xl mt-4 w-full p-4 rounded-lg'>
            Get Quote Now
        </button>
        </form>
        </section>

        </div>   
        </Fade> 
  );
};

export default Contact;
