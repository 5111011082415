import React, {useState} from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import ReactGA from "react-ga4";

ReactGA.initialize("AW-10890396324");

const style = {color: "white"}
const Navbar = () => {
    const [nav, setNav] = useState(true);
  
    const handleNav = () => {
      setNav(!nav);
    };

  return (
    

<div className='flex flex-col pt-16 justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
    <h1 className='w-full text-5xl font-bold text-white italic'>KW-Cell</h1>
    <div className='flex flex-col items-center w-full text-center md:text-3xl sm:text-l text-3xl font-bold text-[#F2CC0C]'>
        <a href='tel:+15195940059' style={{ zIndex: 999 }}>
            <h1>+1 519-594-0059</h1>
        </a>
    </div>
    <ul className='hidden md:flex text-[#0C0C0D]'>

    </ul>
</div>
  )
}

export default Navbar